<template>
  <div class="shop-contanier">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        offset="400"
        @load="onLoad"
      >
        <template v-if="list.length > 0">
          <van-card
            v-for="(item, index) in list"
            :key="index"
            num="1"
            :desc="item.shopdesc"
            :title="item.shopname"
            :thumb="'/apis' + item.shopimg"
            @click="goDetail(item)"
          >
            <!-- <template #price>
              <p class="price-text">¥ <span class="money">{{ fmoney(item.price, 2) }}</span></p>
            </template> -->
            <template #price>
              <!-- <p class="text">{{ returnStatus(item.shopstatus) }}</p> -->
              <p class="price-text">价格：面议</p>
            </template>
            <template #num>
              <p class="text">{{ returnStatus(item.shopstatus) }}</p>
              <p class="text-right">x 1</p>
            </template>
            <!-- <template #footer>
              <div v-if="item.shopstatus === '1'">
                <van-button
                  v-if="!item.check"
                  size="mini"
                  @click.stop="handleCheck('add', item)"
                  type="primary"
                  class="btn-cell"
                  native-type="button"
                  >+</van-button
                >
                <van-button
                  v-else
                  size="mini"
                  @click.stop="handleCheck('del', item)"
                  type="warning"
                  class="btn-cell"
                  native-type="button"
                  >-</van-button
                >
              </div>
            </template> -->
          </van-card>
        </template>
        <van-empty v-if="!loading && list.length < 1" image="search" description="暂时没有找到数据" />
      </van-list>
    </van-pull-refresh>
    <!-- <van-submit-bar :price="totalPrice" button-text="提交订单" @submit="onSubmit" class="submit-box">
      <van-checkbox v-model="checked" @click="checkAll">全选</van-checkbox>
      <template #tip>
        可点击查看, <span class="detail" @click="detailShow = true">订单详情</span>
      </template>
    </van-submit-bar> -->
    <!-- <van-submit-bar
      :price="checkedArr.length * 100"
      :currency="''"
      :decimal-length="0"
      :suffix-label="'个店铺'"
      button-text="提交审核"
      @submit="onSubmit"
      class="submit-box"
    >
      <van-checkbox v-model="checked" @click="checkAll">全选</van-checkbox>
      <template #tip>
        注：待审核期间协商商铺价格与出售时间
      </template> -->
    <!-- </van-submit-bar> -->
  </div>
</template>

<script>
export default {
  name: 'Shop',
  data () {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      checked: false,
      totalPrice: 0,
      checkedArr: [],
      detailShow: false,
      page: 0,
      pageSize: 10,
      total: 0,
      alreadyCount: 0,
      maxChooseCount: 0
    }
  },
  watch: {},
  mounted () {
    this.getData()
  },
  methods: {
    getData (val) {
      this.$axios
        .post('/gw/dianpuinfos', {
          currentPage: this.page,
          pageSize: this.pageSize,
          ...val
        })
        .then((res) => {
          const {
            data: { code, data },
            total
          } = res
          if (code === 200) {
            if (data.length === 0) {
              this.list = []
              this.finished = true
            }
            this.total = total
            const dataList = res.data.data
            if (this.page === 1) {
              this.list = dataList
            } else {
              this.list = this.list.concat(dataList)
            }
            this.loading = false
            if (this.list.length >= res.data.total) {
              this.finished = true
              this.loading = false
            }
            this.page++
          } else {
            this.finished = false
            this.loading = false
            this.$router.push({
              name: 'login'
            })
          }
        }).catch(() => {
          this.$toast({
            type: 'error',
            message: '程序出错了'
          })
          this.finished = true
          this.loading = false
        })
    },
    onLoad () {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.getData()
          this.timer = null
        }, 500)
      }
    },
    onRefresh () {
      console.log('onRefresh')
      this.loading = true
      this.page = 1
      this.list = []
      this.refreshing = true
      this.onLoad()
      this.finished = false
      this.refreshing = false
    },
    onSubmit () {
      const idArr = []
      this.checkedArr.map(i => idArr.push(i.id))
      const dianpuid = idArr.join(',')
      this.$axios
        .post('/gw/dianpuinsertexam', { dianpuids: dianpuid })
        .then((res) => {
          const {
            data: { code, msg }
          } = res
          if (code === 200) {
            this.$toast({
              type: 'success',
              message: '提交成功!'
            })
            this.$router.push({
              path: '/self-store'
            })
          } else {
            this.$toast({
              type: 'fail',
              message: msg
            })
          }
        })
    },
    // checkAll () {
    //   if (this.checked) {
    //     let arrPrice = 0
    //     this.list.map((item) => {
    //       item.check = true
    //       arrPrice += item.price
    //     })
    //     this.totalPrice = arrPrice
    //   } else {
    //     this.list.map((item) => {
    //       item.check = false
    //     })
    //     this.totalPrice = 0
    //   }
    // },
    async handleCheck (type, item) {
      if (type === 'add') {
        const result = await this.check()
        if (result) {
          item.check = true
          this.totalPrice += item.price
          this.checkedArr.push(item)
          // if (this.checkedArr.length === this.list.length) {
          //   this.checked = true
          // }
        } else {
          this.$toast({
            type: 'info',
            message: this.alreadyCount === 0 ? `一次最多只能租赁${this.maxChooseCount}个商铺哦` : `已租赁过${this.alreadyCount}个商铺，当前只能再租赁${this.maxChooseCount}个商铺哦`
          })
        }
      } else {
        item.check = false
        const result = this.checkedArr.filter(
          (i) => !(i.id === item.id)
        )
        this.checkedArr = [...result]
      }
    },
    check () {
      return new Promise((resolve) => {
        this.$axios.post('/gw/buycount', {
          buytype: '2'
        }).then(res => {
          if (res.data.code === 200) {
            // 已购数量， 限制数量
            const { alreadynum, limitnum } = res.data.data
            this.alreadyCount = alreadynum
            this.maxChooseCount = alreadynum === 0 ? limitnum : (limitnum - alreadynum)
            if (res.data.data && this.checkedArr.length >= this.maxChooseCount) {
              resolve(false)
            } else {
              resolve(true)
            }
          } else {
            resolve('fail')
          }
        })
      })
    },
    goDetail (item) {
      console.log(111, item)
      this.$router.push({
        name: 'detail',
        query: {
          id: item.id
        }
      })
    },
    returnStatus (status) {
      switch (status) {
        case '1':
          return '待出租'
        case '2':
          return '已出租'
        case '3':
          return '不可租'
        case '4':
          return '锁定中'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.detail {
  color: #1989fa;
}
.submit-box {
  bottom: 50px;
  z-index: 999999;
}
.btn-cell {
  font-weight: bold;
  width: 20px;
  padding: 0px 5px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 16px;
}
.shop-contanier {
  padding-bottom: 2.5rem;
}
</style>
